<template>
  <v-card class="widget-tag ma-0 pa-2"
    flat
    width="100%"
    :color="widget.bg_color"
  >
    <v-sheet color="transparent">
      <div v-html="widget.html_tag"></div>
    </v-sheet>
  </v-card>
</template>

<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    id: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      widget: {},
      accent: this.setting.accent_color,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.getWidget()
  },

  methods: {
    // API req: ウィジェットデータ
    getWidget() {
      this.apiTool.getRequest('common/widget/' + this.id).then( widget => {
        this.widget = widget
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
.widget-tag {
  overflow: scroll;
  margin: 0 .5em;
}
.widget-tag::-webkit-scrollbar {
  display: none;
}
.widget-tag {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
