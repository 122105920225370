<template>
  <v-sheet class="events ma-4"
    width="100%"
    height="400"
    color="transparent"
  >
    <v-list
      class="pa-0 pr-5"
      v-if="events.length"
      color="transparent"
    >
      <v-row no-gutters
        class="event mb-10"
        v-for="event in events"
        :key="event.event_id"
      >
        <!-- タイトル -->
        <v-col cols="12"
        >
          <v-list-item-title class="mb-1 font-weight-bold">
            <h3>{{ event.title }}</h3>
          </v-list-item-title>
        </v-col>

        <!-- 画像 -->
        <v-col cols="5">
          <v-img
            :src="event.image_url"
            :lazy-src="lazyload_img"
            max-width="500px"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <!-- 文章 -->
        <v-col cols="7">
          <v-list-item-content class="pa-0 pl-5">
            <p v-html="event.text"
              class="event-text"
            ></p>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list>

    <v-row
      class="ma-0"
      v-if="!events.length"
    >
      <p>イベント情報がありません。</p>
    </v-row>
  </v-sheet>
</template>

<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      events: [],
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.getNews()
  },

  methods: {
    // API req: イベントデータ
    getNews() {
      this.apiTool.getRequest('common/event/', {limit:10, offset:0}).then( results => {
        if (!results || !results.length) return

        this.events = results
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
.events {
  overflow-y: scroll;
}
.event-text {
  line-height: 1.5em;
}
</style>
