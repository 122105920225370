<template>
  <v-card class="cast-card mx-1 mb-2 pb-2 d-flex flex-column transition-swing"
    color="primary"
    width="100%"
    :elevation="hover ? 20 : 2"
  >
    <!-- キャッチコピー -->
    <v-card-subtitle
      class="d-flex pa-0"
      v-if="castData.catch_copy"
    >
      <v-sheet
        class="catch-copy pl-2 py-1 flex-grow-1"
        color="accent"
        rounded="t"
      >
        {{ castData.catch_copy }}
      </v-sheet>
    </v-card-subtitle>

    <!-- 画像 -->
    <v-card
      class="hero"
      tile
      flat
      :to="{
        name: 'profile',
        params: {
          castId: castData.cast_id,
          castData: castData
        }
      }"
    >
      <v-img
        :src="castData.image_url"
        :lazy-src="lazyload_img"
        min-height="300"
        max-height="350"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <!-- 新人 -->
      <v-sheet
        class="label-new"
        color="accent"
        v-if="castData.is_new"
      >
        <v-icon large>mdi-new-box</v-icon>
      </v-sheet>
    </v-card>

    <!-- 名前 -->
    <v-card-title
      class="d-flex pt-2 pb-0 font-weight-bold"
    >
      <!-- ランキング -->
      <v-sheet
        class="ranking-icon d-flex flex-column"
        color="transparent"
        v-if="castData.ranking"
      >
        <!-- <v-icon
          class="crown"
          left
          color="accent"
        >{{ rankingCrown(castData.ranking) }}</v-icon> -->
        <v-icon
          large
          color="accent"
        >{{ rankingIcon(castData.ranking) }}</v-icon>
      </v-sheet>
      {{ castData.name }} ({{ castData.age }})
    </v-card-title>

    <!-- 3サイズ -->
    <v-card-text class="three-size py-0 font-weight-bold">
      T{{ castData.height }}cm B{{ castData.bust }}({{ castData.cup }}) W{{ castData.waist }} H{{ castData.hip }}
    </v-card-text>

    <v-card-actions class="tags-sns py-0">
      <v-chip-group
        v-if="castData.tags && castData.tags.length > 0 || castData.sns && castData.sns.length > 0"
      >
        <!-- タグクラウド -->
        <v-chip
          class="mb-1 font-weight-bold"
          color="accent"
          small
          v-for="tag in castData.tags"
          :key="tag.tag_id"
        >
          {{ tag.tag_name }}
        </v-chip>

        <!-- SNS -->
        <v-chip
          class="mb-1 px-2 font-weight-bold"
          color="accent"
          small
          outlined
          label
          v-for="sns in castData.sns"
          :key="sns.sns_name"
        >
          <v-icon
            class="mr-1"
            small
          >
            {{ mdiIconName(sns.icon_name) }}
          </v-icon>
          {{ sns.sns_name }}
        </v-chip>
      </v-chip-group>
    </v-card-actions>

    <!-- 出勤 -->
    <v-card-actions
      class="mt-auto py-0"
      v-if="castData.shift_date"
    >
      <span class="ml-1 text-button">
        <v-icon>mdi-clock-outline</v-icon>
        {{ extractTime(castData.start_at) + "〜" + extractTime(castData.end_at) }}
      </span>
      <v-btn
        class="text-button"
        small
        depressed
        absolute right
        color="accent"
        @click="$emit('bookingClicked')"
      >予約
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import { LAYZLOAD_IMG } from '@/literals.js';

export default {
  components: {
  },

  props: {
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      lazyload_img: LAYZLOAD_IMG,
    };
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    },
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
    rankingIcon() {
      return ranking => 'mdi-numeric-' + ranking + '-circle'
    },
    rankingCrown() {
      return ranking => {
        switch (ranking) {
          case 1:
            return 'mdi-chess-king'
          case 2:
            return 'mdi-crown'
          case 3:
            return 'mdi-crown-outline'
        }
      }
    }
  },

  created() {
  },

  methods: {
  }
};
</script>

<style scoped>
.cast-card {
  overflow: hidden;
}
.catch-copy {
  z-index: 1;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.hero {
  position: relative;
}
.label-new {
  position: absolute;
  top: -17px;
  right: -40px;
  padding: 25px 35px 0px 35px;
  transform: rotate(45deg);
}
.tags-sns >>> .v-slide-group__content {
  flex-wrap: wrap;
  flex: auto;
  padding: 0;
}
.text-button {
  line-height: 2em;
}
.ranking-icon {
  position: relative;
  /* top: 4px; */
}
.crown {
  position: absolute;
  top: -18px;
}
</style>
