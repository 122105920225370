<template>
  <v-card class="image-banner"
    flat
    width="100%"
    color="transparent"
    link
    target="_blank"
    :href="banner.link_url"
  >
    <v-img
      :src="banner.image_url"
      :lazy-src="lazyload_img"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="accent"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    id: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      banner: {},
      lazyload_img: LAYZLOAD_IMG,
      accent: this.setting.accent_color,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.getWidget()
  },

  methods: {
    // API req: ウィジェットデータ
    getWidget() {
      this.apiTool.getRequest('common/banner/' + this.id).then( banner => {
        this.banner = banner
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
</style>
