<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <!-- 動的コンポーネント -->
    <v-row>
      <v-col cols="12" :sm="component.is_half_width && 6"
        v-for="component in components"
        :key="component.display_order"
      >
        <v-card class="my-4"
          flat
          elevation="0"
          color="transparent"
        >
          <v-card-title class="mb-1 primary font-weight-bold"
            v-if="component.display_name"
          >
            <h3>{{ component.display_name }}</h3>
          </v-card-title>

          <v-card-actions class="pa-0 align-self-stretch" :class="component.bg_color">
            <component :is="componentName(component.component_name)"
              :siteApi="siteApi"
              :siteData="siteData"
              :setting="setting"
              :casts="casts"
              :id="componentId(component.component_name)"
            >
            </component>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { ApiTool, AlertApiError } from '@/module.js';

import HomeBannerSlider from '@/components/HomeBannerSlider.vue'
import HomeAvailableCastList from '@/components/HomeAvailableCastList.vue'
import HomeTodayCastList from '@/components/HomeTodayCastList.vue'
import HomeNewCastInfo from '@/components/HomeNewCastInfo.vue'
import HomeNews from '@/components/HomeNews.vue'
import HomeEvent from '@/components/HomeEvent.vue'
import HomePickupVideo from '@/components/HomePickupVideo.vue'
import HomeEmail from '@/components/HomeEmail.vue'
import CastRanking from '@/components/_CastRanking.vue'
import ImageBanner from '@/components/_ImageBanner.vue'
import WidgetTag from '@/components/_WidgetTag.vue'

//***************************************************
//
// Component
//
//***************************************************
export default {
  components: {
    'banner-slider': HomeBannerSlider,
    'shop-news': HomeNews,
    'event': HomeEvent,
    'pickup-video': HomePickupVideo,
    'email': HomeEmail,
    'available-casts': HomeAvailableCastList,
    'today-casts': HomeTodayCastList,
    'new-casts': HomeNewCastInfo,
    'cast-ranking': CastRanking,
    'image-banner': ImageBanner,
    'widget-tag': WidgetTag,
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      components: [],
      casts: [],
      orderDelimitar: '_',
      primary: this.setting.primary_color,
      accent: this.setting.accent_color,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    calcCols() {
      return percent => 12 * (percent / 100)
    },
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    },
    componentName() {
      return name => name.split(this.orderDelimitar)[0]
    },
    componentId() {
      return name => {
        const i = name.indexOf(this.orderDelimitar)
        if (i > -1) {
          const ary = name.split(this.orderDelimitar)
          return parseInt(ary[ary.length - 1])
        } else {
          return 1
        }
      }
    },
    needsCastsData() {
      return name => name.indexOf('cast') > -1 ? true : false
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getComponents()
    .catch(error => {
      AlertApiError(error)
    })
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async getComponents() {

        await this.getCasts()
        this.getComponentList()
    },

    // API req: コンポーネントリスト
    getComponentList() {
      return this.apiTool.getRequest('home/component/').then( components => {
        this.components = components
      })
    },

    // API req: キャストデータ
    getCasts() {
      return this.apiTool.getRequest('cast/').then( casts => {
        if (!casts) return
        this.casts = casts
      })
    },
  }
};
</script>

<style scoped>
</style>
