<template>
  <v-card class="today-casts px-1 py-2"
    flat
    width="100%"
    color="transparent"
  >
    <!-- 出勤０ -->
    <v-row
      v-if="castsIn.length == 0"
    >
      <v-col cols="12">
        <v-card flat class="transparent">
          <v-card-text>
            本日出勤予定のキャストがいません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- 出勤あり -->
    <v-row
      no-gutters
      v-else
    >
      <v-col cols="6" sm="3" md="2" xl="2"
        class="cast d-flex flex-wrap mb-2"
        v-for="cast in castsIn"
        :key="cast.cast_id"
      >
        <cast-card
          :setting="setting"
          :castData="cast"
          @bookingClicked="bookingClicked(cast)"
        ></cast-card>
      </v-col>
    </v-row>

    <!-- 予約フォーム -->
    <v-bottom-sheet
      v-model="openBooking"
      inset
      persistent
      overlay-opacity="0.5"
    >
      <form-booking
        :siteApi="siteApi"
        :siteData="siteData"
        :setting="setting"
        :shiftInfo="shiftInfo"
        @cancel="openBooking = false"
      ></form-booking>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import moment from 'moment'
import { BizHour, ApiTool, AlertApiError } from '@/module.js';
import CastCardMini from '@/components/_CastCardMini.vue'
import FormCastBooking from '@/components/_FormCastBooking.vue'

export default {
  components: {
    'cast-card': CastCardMini,
    'form-booking': FormCastBooking,
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    casts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      castsIn: [],
      shiftInfo: {cast_name: ''},
      openBooking: false,
      bizHour: new BizHour(this.siteData),
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    dateToday() {
      return moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
    },
  },

  created() {
    this.setDateShifts(this.dateToday)
  },

  methods: {
    // API req: 当日シフトを取得して当日出勤配列を生成する
    setDateShifts(dateStr) {
      if (!this.casts.length) return

      this.apiTool.getRequest('shift/date/', {date: dateStr}).then( results => {
        if (!results || !results.length) return

        //取得したシフトをキャストデータに紐付け
        results.forEach( shift => {
          this.casts.forEach( cast => {
            if (cast.cast_id === shift.cast_id) {
              this.castsIn.push({...cast, ...shift})
            }
          })
        })
      })
      .catch( error => AlertApiError(error) )
    },

    //予約ボタンクリック：フォームオープン
    bookingClicked(cast) {
      this.shiftInfo.cast_id = cast.cast_id
      this.shiftInfo.cast_name = cast.name
      this.shiftInfo.shift_date = cast.shift_date
      this.shiftInfo.start_at = cast.start_at
      this.shiftInfo.end_at = cast.end_at
      this.openBooking = true
    },
  }
};
</script>

<style scoped>
</style>
