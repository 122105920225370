<template>
  <v-sheet class="news ma-4"
    width="100%"
    height="400"
    color="transparent"
  >
    <v-list
      class="pa-0 pr-5"
      v-if="news.length"
      color="transparent"
    >
      <v-row no-gutters
        class="news-item mb-10"
        v-for="item in news"
        :key="item.news_id"
      >
        <!-- タイトル -->
        <v-col cols="8"
        >
          <v-list-item-title class="mb-1 font-weight-bold">
            <h3>{{ item.title }}</h3>
          </v-list-item-title>
        </v-col>
        <!-- 日付 -->
        <v-col cols="4"
          align="right"
        >
          <v-list-item-subtitle class="mb-1">
            {{ jpDate(item.created_at) }}
          </v-list-item-subtitle>
        </v-col>
        <!-- 画像 -->
        <v-col cols="5">
          <v-img
            :src="item.image_url"
            :lazy-src="lazyload_img"
            max-width="500px"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <!-- 文章 -->
        <v-col cols="7">
          <v-list-item-content class="pa-0 pl-5">
            <p v-html="item.text"
              class="news-text"
            ></p>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list>

    <v-row
      class="ma-0"
      v-if="!news.length"
    >
      <p>新人情報がありません。</p>
    </v-row>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ja'
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      news: [],
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    jpDate() {
      return date =>  moment(date).format('M/D (dd) HH:mm')
    },
  },

  created() {
    this.getNews()
  },

  methods: {
    // API req: ニュースデータ
    getNews() {
      this.apiTool.getRequest('common/news/', {limit:10, offset:0}).then( results => {
        if (!results || !results.length) return

        this.news = results
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
.news {
  overflow-y: scroll;
}
.news-text {
  line-height: 1.5em;
}
</style>
