<template>
  <v-card class="cast-card pb-2 mx-auto d-flex flex-column transition-swing"
    color="primary"
    width="95%"
    :elevation="hover ? 20 : 2"
  >
    <!-- 画像 -->
    <v-card
      class="hero mb-1"
      tile
      flat
      :to="{
        name: 'profile',
        params: {
          castId: castData.cast_id,
          castData: castData
        }
      }"
    >
      <v-img
        :src="castData.image_url"
        :lazy-src="lazyload_img"
        min-height="230"
        max-height="280"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <!-- 新人 -->
      <v-sheet
        class="label-new"
        color="accent"
        v-if="castData.is_new"
      >
        <v-icon large>mdi-new-box</v-icon>
      </v-sheet>
    </v-card>

    <!-- 名前 -->
    <v-card-title
      class="align-self-center pa-0 text-body-1 font-weight-bold"
    >
      {{ castData.name }}
    </v-card-title>

    <!-- スロット -->
    <slot name="below-name"></slot>

    <!-- 出勤 -->
    <v-card-title class="shift align-self-center pa-0 text-body-1 font-weight-bold">
      <span>
        <v-icon small>mdi-clock-outline</v-icon>
        {{ extractTime(castData.start_at) + "〜" + extractTime(castData.end_at) }}
      </span>
    </v-card-title>

    <!-- 予約 -->
    <v-card-actions
      class="mt-auto py-0"
      v-if="castData.shift_date"
    >
      <v-btn
        class="flex-grow-1 mt-1 pa-0"
        small
        depressed
        color="accent"
        @click="$emit('bookingClicked')"
      >予約
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import { LAYZLOAD_IMG } from '@/literals.js';

export default {
  components: {
  },

  props: {
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      lazyload_img: LAYZLOAD_IMG,
    };
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    },
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
  },

  created() {
  },

  methods: {
  }
};
</script>

<style scoped>
.cast-card {
  overflow: hidden;
}
.hero {
  position: relative;
}
.label-new {
  position: absolute;
  top: -17px;
  right: -40px;
  padding: 25px 35px 0px 35px;
  transform: rotate(45deg);
}
</style>
