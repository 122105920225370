<template>
  <v-card class="cast-ranking px-1 py-2"
    flat
    width="100%"
    color="transparent"
  >
    <!-- 該当なし -->
    <v-row
      v-if="rankinCasts.length == 0"
    >
      <v-col cols="12">
        <v-card flat class="transparent">
          <v-card-text>
            ランキングに該当するキャストがいません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- 該当あり -->
    <v-row
      dense
      v-else
    >
      <v-col cols="6" sm="3"
        class="cast d-flex flex-wrap"
        v-for="cast in rankinCasts"
        :key="cast.cast_id"
      >
        <cast-card
          :setting="setting"
          :castData="cast"
        >
        </cast-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ApiTool, AlertApiError } from '@/module.js';
import CastCard from '@/components/_CastCard.vue'

export default {
  components: {
    'cast-card': CastCard,
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    casts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      rankinCasts: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.setCastsData()
    .catch(error => {
        AlertApiError(error)
    })
  },

  methods: {
    async setCastsData() {
      if (!this.casts.length) return

      await this.setCastRanking()
      if (!this.rankinCasts.length) return

      await Promise.all([
        this.getCastsTags(),
        this.getCastsSns()
      ])
    },

    // API req: 取得したランキングデータを元にランキング配列を生成
    setCastRanking() {
      return this.apiTool.getRequest('common/cast-ranking/').then( rankings => {
        rankings.forEach( ranking => {
          this.casts.forEach( cast => {
            if (cast.cast_id === ranking.cast_id) {
              // リアクティブ保持
              cast.tags = []
              cast.sns = []
              this.rankinCasts.push({...cast, ...{ranking: ranking.ranking}})
            }
          })
        })
      })
    },

    // API req: タグデータ
    getCastsTags() {
      return this.apiTool.getRequest('cast/tag/').then( tags => {
        this.rankinCasts.map( cast => {
          cast.tags = tags.filter( tag => tag.cast_id === cast.cast_id )
        })
      })
    },

    // API req: SNSデータ
    getCastsSns() {
      return this.apiTool.getRequest('cast/sns/').then( snsRows => {
        this.rankinCasts.map( cast => {
          cast.sns = snsRows.filter( row => row.cast_id === cast.cast_id )
        })
      })
    },
  }
};
</script>

<style scoped>
</style>
