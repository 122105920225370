<template>
  <v-sheet class="new-casts ma-4"
    width="100%"
    height="400"
    color="transparent"
  >
    <v-list
      class="pa-0 pr-5"
      v-if="newCasts.length"
      color="transparent"
    >
      <v-row no-gutters
        class="cast mb-10"
        v-for="cast in newCasts"
        :key="cast.cast_id"
      >
        <v-col cols="12">
          <!-- キャッチコピー -->
          <v-list-item-title
            class="mb-1 font-weight-bold"
          >
            <h3>{{ cast.catch_copy }}</h3>
          </v-list-item-title>
        </v-col>
        <v-col cols="5">
          <!-- 画像 -->
          <v-card
            tile
            flat
            color="transparent"
            :to="{
              name: 'profile',
              params: {
                castId: cast.cast_id,
                castData: cast
              }
            }"
          >
            <v-img
              :src="cast.image_url"
              :lazy-src="lazyload_img"
              max-width="500px"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="accent"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>

          <!-- 日付と名前 -->
          <v-list-item-title
            class="font-weight-bold"
          >
            <h3>{{ cast.name }}</h3>
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-bold"
          >
            <h3>{{ jpDate(cast.start_date) }}入店</h3>
          </v-list-item-subtitle>
        </v-col>

        <v-col cols="7">
          <!-- コメント -->
          <v-list-item-content class="pa-0 pl-5">
            <p v-html="cast.comment_primary"
              class="cast-comment"
            ></p>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list>

    <v-row
      class="ma-0"
      v-if="!newCasts.length"
    >
      <p>新人情報がありません。</p>
    </v-row>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    casts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      newCasts: [],
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    jpDate() {
      return date =>  moment(date).format('M月D日')
    },
  },

  created() {
    this.getNewCasts()
  },

  methods: {
    // API req: 新人データ
    getNewCasts() {
      if (!this.casts.length) return

      this.apiTool.getRequest('common/new-cast/').then( results => {
        if (!results || !results.length) return

        results.map( newCast => {
          this.casts.map( cast => {
            if (newCast.cast_id === cast.cast_id) {
              this.newCasts.push({...cast, ...newCast})
            }
          })
        })
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
.new-casts {
  overflow-y: scroll;
}
.cast-comment {
  line-height: 1.5em;
}
</style>
