import { render, staticRenderFns } from "./HomeNews.vue?vue&type=template&id=03cb1233&scoped=true&"
import script from "./HomeNews.vue?vue&type=script&lang=js&"
export * from "./HomeNews.vue?vue&type=script&lang=js&"
import style0 from "./HomeNews.vue?vue&type=style&index=0&id=03cb1233&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03cb1233",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VImg,VList,VListItemContent,VListItemSubtitle,VListItemTitle,VProgressCircular,VRow,VSheet})
