import { render, staticRenderFns } from "./HomeNewCastInfo.vue?vue&type=template&id=9e35bfc6&scoped=true&"
import script from "./HomeNewCastInfo.vue?vue&type=script&lang=js&"
export * from "./HomeNewCastInfo.vue?vue&type=script&lang=js&"
import style0 from "./HomeNewCastInfo.vue?vue&type=style&index=0&id=9e35bfc6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e35bfc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCol,VImg,VList,VListItemContent,VListItemSubtitle,VListItemTitle,VProgressCircular,VRow,VSheet})
