<template>
  <v-card class="px-4 py-5"
    width="100%"
    color="transparent"
  >
    <v-row class="align-bottom">
      <v-col cols="12" sm="6" md="5">
        <v-img :src="mailmagaImg">
        </v-img>
      </v-col>
      <!-- フォーム -->
      <v-col cols="12" sm="6" md="7">
        <v-form :ref="'form-email'">
          <v-row>
            <v-col cols="9">
              <v-text-field
                class="elevation-5"
                v-model.trim="addressInput"
                outlined
                :dense="$vuetify.breakpoint.smAndDown"
                label="メールアドレスを入力"
                hide-details
                required
                :rules="[val => !!val || '', val => /.+@.+\..+/.test(val) || '']"
              ></v-text-field>
            </v-col>
            <v-col cols="3"
              class="d-flex justify-end align-end"
            >
              <v-btn class="elevation-5"
                :class="$vuetify.breakpoint.mdAndUp && 'py-7'"
                :x-large="$vuetify.breakpoint.mdAndUp"
                color="primary"
                @click="createEmail()"
              >{{ $vuetify.breakpoint.smAndDown ? '登録' : 'メルマガ登録' }}</v-btn>
            </v-col>
          </v-row>
          <!-- 補足 -->
          <v-row>
            <v-col>
              <v-sheet
                color="transparent"
              >
                <div v-html="mailmagaDescription"></div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { MAILMAGA_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      addressInput: '',
      mailmagaDescription: '',
      mailmagaImg: MAILMAGA_IMG,
      snackbar: {open: false, color: 'primary', message: ''},
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.getDescription()
  },

  methods: {
    getDescription() {
      this.apiTool.getRequest('template-string/', {usedIn: 'site_email'}).then( result => {
        const target = result.find( template => template.item_name === 'site_mailmaga_description')
        if (target !== undefined) this.mailmagaDescription = target.content
      })
      .catch( error => AlertApiError(error) )
    },

    createEmail() {
      if (!this.$refs['form-email'].validate()) {
        this.snackbar = {...{color:'warning', message: '正しいメールアドレスを入力してください。', open: true}}
        return
      }

      const apiPartial = 'email/create/'
      const payload = JSON.stringify({email_address: this.addressInput})

      this.apiTool.apiReqWithData('POST', apiPartial, payload).then(() => {
        this.snackbar = {...{color:'success', message: 'メルマガ登録が完了しました。', open: true}}
      })
      .catch( error => AlertApiError(error) )
    },
  }
};
</script>

<style scoped>
</style>
