<template>
  <v-card class="banner-slider"
    flat
    width="100%"
    color="transparent"
  >
    <v-card-text
      class="py-6"
      v-if="!slides.length"
    >
      スライダー用の画像が登録されていません。
    </v-card-text>

    <v-carousel
      cycle
      hide-delimiters
      show-arrows
      interval="7000"
      height="unset"
    >
      <v-carousel-item
        eager
        v-for="slide in slides"
        :key="slide.slide_id"
      >
        <v-img
          eager
          :src="slide.image_url"
          :lazy-src="lazyload_img"
          height="100%"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0 justify-center"
              align="center"
            >
              <v-progress-circular
                indeterminate
                color="accent"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      slides: [],
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
  },

  created() {
    this.getSlides()
  },

  methods: {
    // API req: スライドデータ
    getSlides() {
      this.apiTool.getRequest('common/banner-slider/').then( results => {
        if (!results || !results.length) return

        this.slides = results
      })
      .catch( error => AlertApiError(error) )
    },
  }
};
</script>

<style scoped>
.v-carousel {
  max-height: 500px;
}
</style>
