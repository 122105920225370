<template>
  <v-card class="available-casts px-1 py-2"
    flat
    width="100%"
    color="transparent"
  >
    <!-- 空き０ -->
    <v-row
      v-if="!castsAvailable.length"
    >
      <v-col cols="12">
        <v-card flat class="transparent">
          <v-card-text>
            {{ noRecordMessage }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- 空きあり -->
    <v-row
      no-gutters
      v-else
    >
      <v-col cols="6" sm="3" md="2" xl="2"
        class="cast d-flex flex-wrap"
        v-for="cast in castsAvailable"
        :key="cast.cast_id"
      >
        <cast-card
          :setting="setting"
          :castData="cast"
          @bookingClicked="bookingClicked(cast)"
        >
          <template v-slot:below-name>
            <span class="text-caption font-weight-bold" align="center"
            >{{ howlongTilAvailable(cast.minsTilAvailable) }}</span>
          </template>
        </cast-card>
      </v-col>
    </v-row>

    <!-- 予約フォーム -->
    <v-bottom-sheet
      v-model="openBooking"
      inset
      persistent
      overlay-opacity="0.5"
    >
      <form-booking
        :siteApi="siteApi"
        :siteData="siteData"
        :setting="setting"
        :shiftInfo="shiftInfo"
        @cancel="openBooking = false"
      ></form-booking>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import moment from 'moment'
import { BizHour, ApiTool, AlertApiError } from '@/module.js';
import CastCardMini from '@/components/_CastCardMini.vue'
import FormCastBooking from '@/components/_FormCastBooking.vue'

export default {
  components: {
    'cast-card': CastCardMini,
    'form-booking': FormCastBooking,
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    casts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      castsIn: [],
      castsAvailable: [],
      thresholdMins: 60,
      shiftInfo: {cast_name: ''},
      openBooking: false,
      bizHour: new BizHour(this.siteData),
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    howlongTilAvailable() {
      return mins => mins ? (mins + '分程で空く予定') : '待ち時間なし'
    },
    noRecordMessage() {
      const now = moment(new Date())
      const opening = this.bizHour.getBizOpening(new Date())
      const closing = this.bizHour.getBizClosing(new Date())

      if (now.isBefore(opening) || now.isAfter(closing)) {
        return "当店の営業時間は " + moment(opening).format('HH:mm') + " 〜 " + moment(closing).format('HH:mm') + " になります。"
      } else {
        return "現在空いている、もしくはもうすぐ空くキャストがいません。"
      }
    },
  },

  created() {
    this.getAvailableCasts()
    .catch(error => AlertApiError(error) )
  },

  methods: {
    async getAvailableCasts() {
      if (!this.casts.length) return

      await this.setCastsIn()
      if (!this.castsIn.length) return

      await this.setCastsAvailable()
    },

    // API req: 現在出勤中のシフトデータを取得して出勤中キャスト配列を生成する
    setCastsIn() {
      const now = moment(new Date()).format('YYYY-MM-DD HH:mm')

      return this.apiTool.getRequest('shift/in/', {dateTime: now}).then( results => {
        if (!results || !results.length) return

        //取得したシフトデータで出勤中キャスト配列を生成
        results.map( shift => {

          this.casts.map( cast => {
            if (cast.cast_id === shift.cast_id) {
              this.castsIn.push({...cast, ...shift})
            }
          })
        })
      })
    },

    // API req: 範囲の予約データと照合して現在空きキャスト配列を生成する
    setCastsAvailable() {
      const now = moment(new Date()).format('YYYY-MM-DD HH:mm')
      const toDate = moment(new Date()).add(this.thresholdMins, 'm').format('YYYY-MM-DD HH:mm')
      const query = {fromDate: now, toDate: toDate}

      return this.apiTool.getRequest('booking/range/', query).then( results => {
        // if (!results || !results.length) return

        //予約があったらfilter除外して空きキャストを蓄積
        this.castsAvailable = this.castsIn.filter( cast => {
          let hasBooking = false

          results.map( booking => {
            //予約あり
            if (booking.cast_id === cast.cast_id) {
              hasBooking = true

              //ただし、現行予約が30分以内で終了なら空き配列に追加
              if (moment(booking.end_at).isBefore(moment(now).add(30, 'm'))) {
                cast.minsTilAvailable = moment(booking.end_at).diff(now, 'minutes')
                hasBooking = false
              }
            }
          })
          return !hasBooking
        })
      })
    },

    //予約ボタンクリック：フォームオープン
    bookingClicked(cast) {
      this.shiftInfo.cast_id = cast.cast_id
      this.shiftInfo.cast_name = cast.name
      this.shiftInfo.shift_date = cast.shift_date
      this.shiftInfo.start_at = cast.start_at
      this.shiftInfo.end_at = cast.end_at
      this.openBooking = true
    },
  }
};
</script>

<style scoped>
>>> .shift {
  display: none;
}
</style>
