<template>
  <v-sheet class="pickup-video ma-4"
    width="100%"
    height="400"
    color="transparent"
  >
    <v-row no-gutters
      class="justify-center"
      v-if="videos.length"
    >
      <v-col cols="12"
        class="mb-5"
        v-for="video in videos"
        :key="video.id"
      >
        <p>{{ video.video_name }}</p>
        <video
          controls
          :poster="video.poster_url"
          :src="video.video_url"
        ></video>
      </v-col>
    </v-row>

    <v-row
      class="ma-0"
      v-if="!videos.length"
    >
      <p>ピックアップ動画がありません。</p>
    </v-row>
  </v-sheet>
</template>

<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    casts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      videos: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    castName() {
      return castId => {
        const target = this.casts.find( cast => cast.cast_id === castId )
        if (target !== undefined) return target.name
      }
    }
  },

  created() {
    this.getVideos()
    .catch( error => AlertApiError(error) )
  },

  methods: {
    async getVideos() {
      await this.getPickupVideos()

      if (this.videos.some( video => video.cast_id === 0 )) {
        this.setSiteVideos()
      }
      if (this.videos.some( video => video.cast_id > 0 )) {
        this.setCastVideos()
      }
    },

    getPickupVideos() {
      return this.apiTool.getRequest('common/pickup-video/').then( results => {
        if (!results || !results.length) return

        results.map( row => {
          row.id = String(row.video_id) + String(row.cast_id)
          row.video_url = ''
          row.poster_url = ''
          this.videos.push(row)
        })
      })
    },

    //サイト動画のセット
    setSiteVideos() {
      return this.apiTool.getRequest('common/site-video/').then( results => {
        if (!results || !results.length) return

        this.videos.map( pickupV => {
          const target = results.find( siteV => siteV.video_id === pickupV.video_id )
          if (target !== undefined) {
            pickupV.video_url = target.video_url
            pickupV.poster_url = target.poster_url
            pickupV.video_name = target.video_name
          }
        })
      })
    },

    //キャスト動画のセット
    setCastVideos() {
      return this.apiTool.getRequest('common/cast-video/').then( results => {
        if (!results || !results.length) return

        this.videos.map( pickupV => {
          const target = results.find( castV => castV.video_id === pickupV.video_id )
          if (target !== undefined) {
            pickupV.video_url = target.video_url
            pickupV.poster_url = target.poster_url
            pickupV.video_name = this.castName(target.cast_id)
          }
        })
      })
    },
  }
};
</script>

<style scoped>
.pickup-video {
  overflow-y: scroll;
}
video {
  max-width: 100%;
}
</style>
